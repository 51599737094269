import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import LazyLoad from 'react-lazyload'

import { ProductPicture, ProductPicturePlaceholder } from 'components/product/ProductPicture'
import { ProductPrice } from 'components/product/ProductPrice'
import { ProductControls } from 'components/product/ProductControls'
import { Tags } from '../tags/Tags'
import { Ribbon } from '../tags/Ribbon'
import { QuantityWidget } from './QuantityWidget'

import { block } from 'utils/classnames'
import { pushHomepageEvent } from 'utils/googletagmanager'

import './Card.sass'

const PicturePlaceholder = <ProductPicturePlaceholder parent='ProductCard' />

export const Card = props => {
  const {
    product,
    isMenu,
    isLast,
    timeFrame,
    quantity,
    onChange,
    onClick,
    saleIsBlocked = false,
    isDemo = false
  } = props

  const isOutOfStockProduct = product?.stock === 0 || false

  const handleChange = useCallback((publicationId, newQuantity) => {
    if (newQuantity > quantity) {
      pushHomepageEvent('AddToCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    if (newQuantity < quantity) {
      pushHomepageEvent('RemoveFromCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    onChange && onChange(publicationId, newQuantity)
  }, [product, quantity])

  return (
    <div className={`${block('product-card')} grid-col-xxs-6 grid-col-xs-4 grid-col-lg-4 grid-col-xl-3`}>
      <div className={block('product-card', 'image', { isMenu, isOutOfStock: isOutOfStockProduct })} onClick={onClick}>
        <LazyLoad offset={250} placeholder={PicturePlaceholder} once>
          <ProductPicture product={product} parent='ProductCard' />
          {isDemo
            ? null
            : (
              <QuantityWidget
                quantity={quantity}
                isLast={isLast}
              />
            )}
        </LazyLoad>
      </div>

      <div className={block('product-card', 'description')}>
        <p
          className={block('product-card', 'title', { isOutOfStock: isOutOfStockProduct })}
          title={product.title}
          onClick={onClick}
        >
          {product.title}
        </p>
        <Tags tags={product.tags} />
      </div>

      <div className={block('product-card', 'cart')}>
        <ProductPrice
          itemType={product.productTypeName}
          price={product.price}
          isOutOfStock={isOutOfStockProduct}
          originalPrice={product.originalPrice || product.price}
        />
        <div className={block('product-card', 'cta')}>
          {isOutOfStockProduct
            ? <div className={block('product-card', 'outOfStock')}>Victime de son success</div>
            : (
              <ProductControls
                publicationId={product.idProductPublication}
                quantity={quantity}
                onChange={handleChange}
                timeFrame={timeFrame}
                isLast={isLast}
                disabled={saleIsBlocked}
                noAddButton={isDemo}
              />
            )}
        </div>
      </div>
      <Ribbon tags={product.tags} />
    </div>
  )
}

Card.propTypes = {
  product: PropTypes.shape({
    picture: PropTypes.string,
    title: PropTypes.string,
    tags: PropTypes.array,
    productTypeName: PropTypes.string,
    price: PropTypes.number,
    originalPrice: PropTypes.number,
    idProductPublication: PropTypes.number
  }),
  isMenu: PropTypes.bool,
  isLast: PropTypes.bool,
  timeFrame: PropTypes.object,
  quantity: PropTypes.number,
  images: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  saleIsBlocked: PropTypes.bool
}

export default React.memo(Card)
