import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { CompanySelector } from './CompanySelector'
import { Header } from './Header'
import { Title } from './Title'
import { Offices } from './Offices'
import { Offers } from './Offers'
import { Footer } from './Footer'

import { block } from 'utils/classnames'

import './B2BPanel.sass'

const b = block.with('b2b-panel')

export const selectOfficeFromAddress = (offices, address) => {
  if (!Array.isArray(offices) || !address) {
    return null
  }

  const office = offices.find(office => (
    office.address === address.address
  ))

  if (office) {
    return office
  }

  return offices
    .find(office => (office.address === 'NO_ADDRESS')) || null
}

export const B2BPanel = props => {
  const {
    company = null,
    offices = [],
    companies = [],
    customer,
    error = '',
    selectedAddress = null,
    selectCompany,
    updateDeliveryFromAddress,
    onClose
  } = props

  const [selectedOffice, setSelectedOffice] = useState(selectOfficeFromAddress(offices, selectedAddress))
  const selectOffice = useCallback((office) => {
    if (!office?.address) {
      return null
    }
    setSelectedOffice(office)
    if (
      office.address &&
      office.latitude &&
      office.longitude &&
      office.address !== 'NO_ADDRESS'
    ) {
      updateDeliveryFromAddress && updateDeliveryFromAddress({
        address: office.address,
        latitude: office.latitude,
        longitude: office.longitude
      })
    }
  }, [offices, updateDeliveryFromAddress])

  useEffect(() => {
    setSelectedOffice(selectOfficeFromAddress(offices, selectedAddress))
  }, [selectedAddress, offices])

  useEffect(() => {
    if (!customer?.firstName) {
      onClose && onClose()
    }
    if (!Array.isArray(company?.offices) &&
    !Array.isArray(companies)) {
      onClose && onClose()
    }
  }, [onClose, customer, companies, company])

  if (
    !Array.isArray(company?.offices) &&
    !Array.isArray(companies)
  ) {
    return null
  }
  if (company === null) {
    return (
      <div className={b('', { companySelector: true })}>
        <CompanySelector
          companies={companies}
          customerId={customer?.id}
          firstName={customer?.firstName}
          email={customer?.email}
          selectCompany={selectCompany}
          error={error}
          onClose={onClose}
        />
      </div>
    )
  }

  return (
    <div className={b()}>
      <button
        className={b('close')}
        onClick={onClose}
      >
        Fermer
      </button>
      <Header
        logo={company?.logo}
        name={company?.name}
        color={company?.color}
      />
      <Title
        firstName={customer?.firstName}
        hasAddress={!!selectedOffice}
      />
      <Offices
        offices={offices}
        selected={selectedOffice}
        onClick={selectOffice}
      />
      {
        selectedOffice
          ? (
            <Offers
              offers={selectedOffice?.offers}
              deliveryFee={selectedOffice?.deliveryFee}
              discount={selectedOffice?.discount}
            />
          )
          : null
      }
      <Footer
        referredAmount={customer?.referralProgram?.referredAmount}
        referrerAmount={customer?.referralProgram?.referrerAmount}
        onClose={onClose}
      />
    </div>
  )
}
B2BPanel.propTypes = {
  company: PropTypes.object,
  offices: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
  customer: PropTypes.object,
  error: PropTypes.string,
  selectedAddress: PropTypes.object,
  selectCompany: PropTypes.func,
  updateDeliveryFromAddress: PropTypes.func,
  onClose: PropTypes.func
}
export default B2BPanel
