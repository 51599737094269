import React from 'react'

import AdCard from 'components/product/AdCard'
import ProductCard from 'components/product/ProductCard'

export const Catalog = props => {
  const {
    publications = [],
    adCards = [],
    hideLastAdCard = false
  } = props

  const items = React.useMemo(() => {
    const list = publications.map((publicationId, i) => (
      `${String(i).padStart(3, '0')}:pub:${publicationId}`)
    )

    Array.isArray(adCards) && adCards
      .forEach(adCard => {
        const position = typeof adCard.position === 'number'
          ? Math.max(adCard.position - 1, 0)
          : list.length
        list.push(`${String(position).padStart(3, '0')}:ad:${adCard.id}`)
      })

    return list
      .sort()
      .map((item, i) => {
        const [type, id] = item.split(':').slice(1)
        if (type === 'pub') {
          return (
            <ProductCard key={id} publicationId={id} />
          )
        }
        const adCard = adCards.find(
          card => String(card.id) === String(id)
        )

        if (i === (list.length - 1) && hideLastAdCard) {
          return null
        }

        return (
          <AdCard key={id} {...adCard} />
        )
      })
  }, [publications, adCards, hideLastAdCard])

  return items
}

export default Catalog
