import React from 'react'

import { block } from 'utils/classnames'

export const FilterAction = (props) => {
  const {
    hasFilters = false,
    handleClose,
    handleReset
  } = props

  return (
    <div className={block('filter-action')}>
      <button onClick={handleClose} className={block('filter-action', 'button-valid')}>
        ok
      </button>
      <button disabled={!hasFilters} onClick={handleReset} className={block('filter-action', 'button-cancel')}>
        Tout retirer
      </button>
    </div>
  )
}

export default FilterAction
