import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { block } from 'utils/classnames'
import { getCompanyLogoUrl } from 'utils/cloudinary'

import './Header.sass'

const b = block.with('b2b-panel-header')

const LOGO_BACKGROUND_GRADIENT = 'linear-gradient(:color, :color 120px, transparent 120px, transparent)'
const NO_LOGO_BACKGROUND_GRADIENT = 'linear-gradient(rgb(0,0,0), rgb(0,0,0) 90px, transparent 90px, transparent)'

export const Header = props => {
  const {
    logo = '',
    name = '',
    color = ''
  } = props

  const logoUrl = useMemo(() => (
    getCompanyLogoUrl(logo)
  ), [logo])

  const headerStyle = useMemo(() => {
    const gradient = logoUrl
      ? LOGO_BACKGROUND_GRADIENT
      : NO_LOGO_BACKGROUND_GRADIENT

    return color
      ? { background: gradient.replace(/:color/g, color) }
      : { background: NO_LOGO_BACKGROUND_GRADIENT }
  }, [logoUrl, color])

  const logoStyle = useMemo(() => (
    logoUrl
      ? {
        backgroundImage: `url(${logoUrl})`,
        backgroundSize: 'contain'
      }
      : {}
  ), [logoUrl])

  return (
    <header
      style={headerStyle}
      className={b('', { 'no-logo': !logoUrl })}
    >
      <div className={b('logo', { 'no-logo': !logoUrl })}>
        <figure style={logoStyle}>
          {
            logoUrl
              ? <img src={logoUrl} alt={name} />
              : null
          }
        </figure>
      </div>
    </header>
  )
}
Header.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string
}
export default Header
