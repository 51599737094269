import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { block } from 'utils/classnames'
import { eur, percentage } from 'utils/numeral'

import './Offers.sass'

const b = block.with('b2b-panel-offers')

export const Offer = props => {
  const {
    type = 'offer',
    title,
    description
  } = props

  return (
    <div className={b('item', { type })}>
      <h4>{title}</h4>
      {
        description
          ? <p dangerouslySetInnerHTML={{ __html: description }} />
          : null
      }
    </div>
  )
}
Offer.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export const Offers = props => {
  const {
    offers = [],
    deliveryFee = 1.5,
    discount = 0
  } = props

  const offerItems = useMemo(() => {
    if (!Array.isArray(offers) || offers.length === 0) {
      return []
    }
    return offers.map(offer => {
      if (!offer || !offer.value || isNaN(parseFloat(offer.value))) {
        return null
      }
      let {
        value = 0,
        type = 'PRICE',
        name = ''
      } = offer

      value = parseFloat(offer.value)
      value = type === 'PERCENTAGE'
        ? percentage(value / 100)
        : eur(value)

      return {
        id: offer.id,
        type: 'offer',
        title: value + ' ' + name,
        description: offer.description
      }
    }).filter(Boolean)
  }, [offers])

  const deliveryFeeItem = useMemo(() => {
    if (typeof deliveryFee !== 'number' || deliveryFee > 0) {
      return null
    }
    return {
      type: 'delivery-fee',
      title: 'Frais de livraison : Offerts'
    }
  }, [deliveryFee])

  const companyDiscountItem = useMemo(() => {
    if (!discount) {
      return null
    }
    return {
      type: 'discount',
      title: eur(discount) + ' de participation employeur'
    }
  }, [discount])

  return (
    <div className={b()}>
      {
        offerItems.map(offer => (
          <Offer
            key={offer.id}
            {...offer}
          />
        ))
      }
      {
        deliveryFeeItem
          ? <Offer key='deliveryFee' {...deliveryFeeItem} />
          : null
      }
      {
        companyDiscountItem
          ? <Offer key='companyDiscount' {...companyDiscountItem} />
          : null
      }
    </div>
  )
}
Offers.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.number,
      type: PropTypes.oneOf(['PRICE', 'PERCENTAGE']),
      name: PropTypes.string,
      description: PropTypes.string
    })
  ),
  deliveryFee: PropTypes.number,
  discount: PropTypes.number
}
export default Offers
