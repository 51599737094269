import PropTypes from 'prop-types'
import React from 'react'

import { ImageResponsive } from 'components/ImageResponsive'

export const BannerImage = props => {
  const {
    href,
    images,
    name = 'Header FoodChéri',
    optimizedQuality = true
  } = props

  const imageUrls = {
    mobile: images?.small,
    tablet: images?.medium,
    desktop: images?.large
  }
  if (optimizedQuality) {
    for (const deviceSize in imageUrls) {
      imageUrls[deviceSize] = imageUrls[deviceSize].replace(/res.cloudinary.com\/.+?\/.+?\/.+?\//, '$&q_auto,f_auto,w_auto/')
    }
  }

  return (
    <div>
      {
        href ? (
          <a
            rel={href[0] !== '#' ? 'noopener noreferrer' : undefined}
            target={href[0] !== '#' ? '_blank' : undefined}
            href={href}
          />
        ) : null
      }
      <ImageResponsive
        {...imageUrls}
        alt={name}
      />
    </div>
  )
}
BannerImage.propTypes = {
  href: PropTypes.string,
  images: PropTypes.shape({
    small: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string
  }),
  name: PropTypes.string
}
export default BannerImage
