import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './AdCard.sass'

const b = block.with('marketing-card')

export const AdCard = props => {
  const {
    asset = '',
    href = '',
    onClick,
    optimizedQuality = true
  } = props

  const className = React.useMemo(() => (
    `${b()} grid-col-xxs-6 grid-col-xs-4 grid-col-lg-4 grid-col-xl-3`
  ), [])

  const linkProps = React.useMemo(() => {
    if (!href || typeof href !== 'string') {
      return null
    }

    return (/^\/?#{1}.+$/).test(href)
      // Internal link
      ? { href: href.replace('/#', '#') }
      // External link
      : {
        href,
        rel: 'noopener',
        target: '_blank'
      }
  }, [href])

  const style = {
    backgroundImage: optimizedQuality ? `url(${asset.replace(/res.cloudinary.com\/.+?\/.+?\/.+?\//, '$&q_auto,f_auto,w_400/')})` : `url(${asset})`
  }

  return typeof onClick === 'function'
    ? (
      <button
        onClick={onClick}
        className={className}
        style={style}
      >
        <span className={b('placeholder')} />
      </button>
    )
    : (
      <a
        {...linkProps}
        disabled={linkProps === null}
        className={className}
        style={style}
      >
        <span className={b('placeholder')} />
      </a>
    )
}
AdCard.propTypes = {
  asset: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func
}
export default AdCard
