import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useRouterLink } from 'hooks/router'

import Card from './Card'

import { getRouteById } from 'config/router'

export const ProductCard = props => {
  const {
    product,
    basketProduct,
    onChange,
    saleIsBlocked = false,
    isDemo = false
  } = props

  if (!product) {
    return null
  }

  const handleClick = useRouterLink(
    getRouteById('product'),
    { productId: product.idProduct }
  )

  const quantity = basketProduct?.quantity || 0
  const isLast = basketProduct?.isLast || false

  const isMenu = useMemo(() => (
    Array.isArray(product.menuItems) && product.menuItems.length > 0
  ), [product])

  const timeFrame = useMemo(() => (
    product.startTime
      ? { startTime: product.startTime, endTime: product.endTime }
      : null
  ), [product])

  return (
    <Card
      product={product}
      isMenu={isMenu}
      isLast={isLast}
      timeFrame={timeFrame}
      quantity={quantity}
      onChange={onChange}
      onClick={handleClick}
      saleIsBlocked={saleIsBlocked}
      isDemo={isDemo}
    />
  )
}

ProductCard.propTypes = {
  product: PropTypes.object,
  basketProduct: PropTypes.shape({
    quantity: PropTypes.number,
    isLast: PropTypes.bool
  }),
  onChange: PropTypes.func,
  saleIsBlocked: PropTypes.bool,
  isDemo: PropTypes.bool
}

export default ProductCard
