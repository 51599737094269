import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { block } from 'utils/classnames'

export const FilterListItem = props => {
  const {
    filter,
    activeFilters,
    checkIcon,
    onClick,
    isAllergen = false
  } = props

  const isActive = useMemo(() => (
    activeFilters.some(activeFilter => activeFilter && (activeFilter.id === filter.id))
  ), [filter, activeFilters])

  const handleClick = useCallback(() => {
    onClick && onClick(filter, isActive)
  }, [onClick, filter, isActive])

  return (
    <li
      className={block('filter-list', 'list-item', { check: checkIcon, active: isActive, allergen: isAllergen })}
      key={filter.id}
      onClick={handleClick}
    >
      {
        filter.icon
          ? <i className={block('list-item-icon', '', { icon: filter.icon })} />
          : null
      }
      {filter.label}
    </li>
  )
}
FilterListItem.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string
  }),
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),
  checkIcon: PropTypes.string,
  onClick: PropTypes.func
}

export const FilterList = (props) => {
  const {
    category,
    title,
    activeFilters,
    onClick,
    isAllergen
  } = props

  const handleClick = useCallback((filter, isActive) => {
    onClick && onClick(category, filter, isActive)
  }, [onClick, category])

  return (
    <section className={block('filter-list')}>
      {
        title ? (
          <h4 className={block('filter-list', 'title')}>{category.label}</h4>
        ) : null
      }
      <ul className={block('filter-list', 'list')}>
        {
          category.filters
            .map(filter => (
              <FilterListItem
                key={filter.id}
                filter={filter}
                activeFilters={activeFilters}
                checkIcon={category.checkIcon}
                onClick={handleClick}
                isAllergen={isAllergen}
              />
            ))
        }
      </ul>
    </section>
  )
}
FilterList.propTypes = {
  category: PropTypes.shape({
    label: PropTypes.string,
    checkIcon: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    )
  }),
  title: PropTypes.string,
  activeFilters: PropTypes.array,
  onClick: PropTypes.func
}
export default FilterList
