import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import { block } from 'utils/classnames'

import {
  removeFilters,
  resetFilters
} from 'actions/home/filter'

import { getAllFilters } from 'reducers'

import './FilterInfo.sass'

export const FilterButton = (props) => {
  const {
    onClick,
    filter
  } = props

  const handleClick = useCallback(() => {
    onClick && onClick(filter)
  }, [onClick, filter])

  return (
    <button
      type='button'
      onClick={handleClick}
      className={block('filter-info', 'applied-filter')}
    >
      {filter?.resultLabel || filter?.label}
    </button>
  )
}

export const FilterInfo = (props) => {
  const {
    activeFilters,
    onRemove,
    onClear
  } = props

  if (activeFilters && activeFilters.length === 0) {
    return null
  }

  const handleReset = useCallback(() => {
    onClear && onClear()
  }, [onClear])

  const getFilterButtons = () => {
    if (!Array.isArray(activeFilters) || activeFilters.length === 0) {
      return null
    }
    return activeFilters
      .filter(Boolean)
      .map((item, index, array) => {
        const filter = activeFilters.find(filter => filter.id === item.id)
        return (
          <>
            <span className={block('filter-info', 'filter-container')}>
              <FilterButton
                filter={filter}
                key={filter.id}
                onClick={onRemove}
              />
              {index < array.length - 2 && (
                ','
              )}
            </span>
            {index < array.length - 2
              ? ' '
              : index === array.length - 2
                ? ' et '
                : null}
          </>
        )
      })
  }

  const filterButtons = getFilterButtons()

  return Array.isArray(filterButtons) && filterButtons.length > 0
    ? (
      <section className={block('filter-info')}>
        Tous les produits&nbsp;
        {filterButtons}
        <button
          key='reset'
          className={block('filter-info', 'reset-filter')}
          onClick={handleReset}
        />
      </section>
    )
    : null
}

export const ConnectFilterInfo = connect(
  (state, props) => ({
    activeFilters: getAllFilters(state, props)
  }),
  dispatch => ({
    onRemove: filters => dispatch(removeFilters(filters)),
    onClear: () => dispatch(resetFilters())
  })
)(FilterInfo)

export default ConnectFilterInfo
