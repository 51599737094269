import PropTypes from 'prop-types'
import React from 'react'

import { Carousel } from 'components/Common/Carousel'

import { block } from 'utils/classnames'

import { BannerImage } from './BannerImage'

import './Banner.sass'

const b = block.with('header-banner')

export const Banner = props => {
  const {
    headers = []
  } = props

  if (!Array.isArray(headers) || headers.length === 0) {
    return null
  }

  return (
    <section className={b()}>
      <Carousel>
        {
          headers
            .map(header => (
              header ? <BannerImage key={header.id} {...header} /> : null
            ))
        }
      </Carousel>
    </section>
  )
}
Banner.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  )
}
export default Banner
