import { connect } from 'react-redux'

import ProductPanel from './ProductPanel'

import {
  getProductDetailsById,
  getProductHasError,
  getPublicationByProductId,
  getCartProduct,
  getSaleIsBlocked,
  isDemoModeEnabled,
  getDevice
} from 'reducers'

import { getProductDetails } from 'actions/home/product'
import { updateCartProducts } from 'actions/home/cart'

export const ConnectedProductPanel = connect(
  (state, props) => {
    const publication = getPublicationByProductId(state, props)
    const publicationId = publication && publication.idProductPublication
    const { isMobile } = getDevice(state)
    return {
      hasError: getProductHasError(state, props),
      saleIsBlocked: getSaleIsBlocked(state, props),
      product: getProductDetailsById(state, props),
      publication,
      basketProduct: getCartProduct(state, { publicationId }),
      isDemo: isDemoModeEnabled(state, props),
      isMobile
    }
  },
  {
    getProductDetails,
    updateCartProducts
  }
)(ProductPanel)

export default ProductPanel
