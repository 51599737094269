import PropTypes from 'prop-types'
import React from 'react'
import { block } from 'utils/classnames'

import './QuantityWidget.sass'

export const QuantityWidget = props => {
  const {
    quantity,
    isLast = false
  } = props

  const visible = Boolean(quantity)

  return (
    <div className={block('product-quantity-widget', { visible })}>
      <span className={block('product-quantity-widget', 'number')}>
        {quantity > 0 ? quantity : null}
      </span>
      {
        isLast
          ? (
            <span className={block('product-quantity-widget', 'last')}>
             Vous avez pris le dernier !
            </span>
          )
          : null
      }
    </div>
  )
}

QuantityWidget.propTypes = {
  quantity: PropTypes.number,
  isLast: PropTypes.bool
}

export default QuantityWidget
