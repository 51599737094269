import Home from 'components/Home'
import LoadingPage from 'components/Common/LoadingPage'
import { parseCookies } from 'nookies'
import * as cookie from 'utils/cookies'

import { getIsPageLoading } from 'reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getPathById } from 'config/router'
import { wrapper } from 'config/createStore'
import { initializeDelivery } from 'actions/home'
import { loadProductPublicationsFromServer, loadActiveHeadersFromServer } from 'api/home/ssr'
import { useEffect } from 'react'

const IndexPage = () => {
  const router = useRouter()
  const isPageLoading = useSelector(getIsPageLoading)
  const dispatch = useDispatch()
  if (router.asPath.includes('/business')) {
    router.push(getPathById('b2b-landing-page'))
    return <LoadingPage withIcon withSpinner />
  }
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    const fetch = async () => {
      await dispatch(initializeDelivery())
    }
    fetch()
  }, [])

  return (
    <>
      {isPageLoading === true
        ? <LoadingPage withIcon withSpinner />
        : isPageLoading === null ? <LoadingPage />
          : <Home />}
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(async ({ store, req }) => {
  const parsedCookies = parseCookies({ req })
  const uuid = parsedCookies[cookie.KEY.cart] ? JSON.parse(parsedCookies[cookie.KEY.cart]).uuid : null

  await store.dispatch(loadActiveHeadersFromServer())
  await loadProductPublicationsFromServer({ uuid, dispatch: store.dispatch, state: store.getState, cookies: parsedCookies })

  return { props: {} }
})

export default IndexPage
