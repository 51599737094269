import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './Title.sass'

const b = block.with('b2b-panel-title')

export const Title = props => {
  const {
    hasAddress = false,
    firstName = ''
  } = props

  return (
    <div className={b()}>
      <h3 className={b('title', { welcome: !hasAddress })}>
        {`${hasAddress ? '' : 'Bienvenue '}${firstName}`.trim()}
        <small>business</small>
      </h3>
      {
        !hasAddress
          ? (
            <h5 className={b('subtitle')}>
                Pour consulter vos <strong>avantages entreprise</strong>, sélectionnez votre adresse de livraison
            </h5>
          )
          : null
      }
    </div>
  )
}
Title.propTypes = {
  hasAddress: PropTypes.bool,
  firstName: PropTypes.string
}
export default Title
