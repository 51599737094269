import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'
import { getCompanyLogoUrl } from 'utils/cloudinary'
import { PreventButton } from 'utils/preventButton'

import './CompanySelector.sass'

const b = block.with('b2b-panel-company-selector')

export const CompanyOption = props => {
  const {
    company,
    selectCompany,
    customerId,
    onClose
  } = props
  const logoSrc = getCompanyLogoUrl(company?.logo) === '' ? '/images/b2b/default-logo.svg' : getCompanyLogoUrl(company?.logo)
  return (
    <PreventButton
      className={b('item')}
      onClick={() => {
        selectCompany(customerId, company)
        onClose()
      }}
    >
      <figure>
        <img src={logoSrc} alt={company?.name} />
        <figcaption>
          <span>{company?.name}</span>
        </figcaption>
      </figure>
    </PreventButton>
  )
}
CompanyOption.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    logo: PropTypes.string
  }),
  onClick: PropTypes.func
}
export const CompanySelector = props => {
  const {
    customerId = '',
    firstName = '',
    email = '',
    error = '',
    companies = [],
    selectCompany,
    onClose
  } = props

  if (!Array.isArray(companies) || companies.length === 0) {
    return null
  }

  return (
    <div className={b()}>
      <h5 className={b('info')}>
        Avant toute chose...
      </h5>

      <p className={b('info')}>
        {firstName}, nous avons remarqué qu'il existe plusieurs entités pour votre mail <strong>@{email.split('@')[1]}</strong>
      </p>

      <p className={b('info')}>
        A quelle entité appartenez-vous ?
      </p>

      <p className={b('selector')}>
        {
          companies.map(company => (
            <CompanyOption
              key={company.id}
              company={company}
              selectCompany={selectCompany}
              customerId={customerId}
              onClose={onClose}
            />
          ))
        }
      </p>

      <p className={b('error')}>
        {
          error
            ? 'Une erreur est survenue, veuillez contacter le service client.'
            : null
        }
      </p>
    </div>
  )
}
CompanySelector.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ),
  selectCompany: PropTypes.func
}
export default CompanySelector
