import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Scrollspy from 'react-scrollspy'

import SectionNav from './SectionNav'
import AnimatedOverlay from 'components/Common/AnimatedOverlay'
import RouteButton from 'components/Router/RouteButton'

import device from 'utils/device'
import { block } from 'utils/classnames'

import './SectionNavList.sass'

const b = block.with('section-nav-list')

export const SectionNavList = ({ sections, hasFilters = false }) => {
  const listElement = useRef(null)
  const [hasScroll, setHasScroll] = useState(true)
  const [firstInvisibleIndex, setFirstInvisibleIndex] = useState(sections.length - 1)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeAnchor, setActiveAnchor] = useState(null)

  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false)
  }, [])
  const openDropdown = useCallback(() => {
    setIsDropdownOpen(true)
  }, [])
  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(state => !state)
  }, [])

  const handleResize = useCallback(() => {
    if (!device.isDesktop(true)) {
      setHasScroll(true)
      return
    }
    setHasScroll(false)
    if (!listElement.current) {
      return
    }
    const items = listElement.current.querySelectorAll('li')
    if (!items) {
      return
    }
    const itemWidths = [...items].map(item => item.offsetWidth)

    let width = listElement.current.offsetWidth
    const activeItem = listElement.current.querySelector('li[data-status="active"]')

    let nextIndex = 0
    if (activeItem) {
      width -= activeItem.offsetWidth
    }

    while (itemWidths[nextIndex] && width >= itemWidths[nextIndex]) {
      if (!activeItem || items[nextIndex].dataset.status === 'inactive') {
        width -= itemWidths[nextIndex]
      }
      nextIndex += 1
    }

    setFirstInvisibleIndex(nextIndex)
    setIsDropdownOpen(false)
  }, [hasScroll, activeAnchor])

  const handleScrollspyUpdate = useCallback((activeElement) => {
    activeElement && activeElement.id && setActiveAnchor(activeElement.id.replace('section_', ''))
  }, [])

  const scrollToSection = (sectionId) => (e) => {
    e.preventDefault()
    const sectionElement = document.getElementById(sectionId)
    if (sectionElement) sectionElement.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [sections, activeAnchor, firstInvisibleIndex])

  const scrollTargets = useMemo(
    () => sections.map(section => 'section_' + section.anchor),
    [sections]
  )

  const isTruncated = useMemo(() => firstInvisibleIndex < sections.length - 1, [firstInvisibleIndex, sections])

  const invisibleActive = useMemo(() => (
    firstInvisibleIndex < sections.length - 1 && sections.find((section, i) => i >= firstInvisibleIndex && section.anchor === activeAnchor)
  ), [sections, firstInvisibleIndex, activeAnchor])

  if (hasScroll) {
    return (
      <SectionNav
        sections={sections}
        hasFilters={hasFilters}
      />
    )
  }

  return (
    <nav className={b('', { trucated: isTruncated })}>
      <Scrollspy
        items={scrollTargets}
        offset={-100}
        onUpdate={handleScrollspyUpdate}
        style={{ display: 'none' }}
      />
      <ul className={b('items', { placeholder: true })} ref={listElement}>
        {
          sections
            .map((section, i) => (
              <li
                key={section.id}
                data-status={section.anchor === activeAnchor ? 'active' : 'inactive'}
                className={b('item')}
              >
                <a onClick={scrollToSection(section.anchor)}>
                  {section.alias || section.title}
                </a>
              </li>
            ))
        }
      </ul>
      <ul className={b('items', { visible: true })}>
        {
          (isTruncated ? sections.slice(0, firstInvisibleIndex) : sections)
            .map((section, i) => (
              <li
                key={section.id}
                className={b(
                  'item',
                  { active: section.anchor === activeAnchor, visible: true }
                )}
              >
                <a onClick={scrollToSection(section.anchor)}>
                  {section.alias || section.title}
                </a>
              </li>
            ))
        }
        {
          invisibleActive
            ? (
              <li
                key='invisible-active'
                className={b('item', { active: true, visible: true, last: true })}
              >
                <a onClick={scrollToSection(invisibleActive.anchor)}>
                  {invisibleActive.alias || invisibleActive.title}
                </a>
              </li>
            )
            : null
        }
        {
          isTruncated
            ? (
              <button
                className={b('dropdown-trigger')}
                onClick={toggleDropdown}
                onMouseEnter={openDropdown}
              >
                Voir plus
              </button>
            )
            : null
        }
      </ul>
      {
        isTruncated
          ? (
            <div className={b('dropdown')}>
              {
                isTruncated && isDropdownOpen
                  ? (
                    <ul
                      className={b('dropdown-list')}
                      onClick={closeDropdown}
                      onMouseLeave={closeDropdown}
                    >
                      {
                        sections.slice(firstInvisibleIndex)
                          .map((section) => section.anchor === activeAnchor
                            ? null
                            : (
                              <li
                                key={section.id}
                                className={b(
                                  'item',
                                  { dropdown: true }
                                )}
                              >
                                <a key={section.id} onClick={scrollToSection(section.anchor)}>
                                  {section.alias || section.title}
                                </a>
                              </li>
                            ))
                      }
                    </ul>
                  )
                  : null
              }
            </div>
          )
          : null
      }
      <div className={b('filter-trigger', { active: hasFilters })}>
        <RouteButton routeId='filter'>
          Filtres
        </RouteButton>
      </div>
      <AnimatedOverlay
        shouldShow={isTruncated && isDropdownOpen}
        noScroll={false}
        onClick={closeDropdown}
      />
    </nav>
  )
}

SectionNavList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      anchor: PropTypes.string,
      alias: PropTypes.string,
      title: PropTypes.string
    })
  ),
  hasFilters: PropTypes.bool
}

export default SectionNavList
