import PropTypes from 'prop-types'
import React from 'react'

import { withRouter } from 'components/Router'

export const RouteSection = props => {
  const { pageRoutes, Component, ...rest } = props

  const RouteSectionComponent = React.useMemo(() => {
    const path = pageRoutes.map(page => page.path)

    const RouteSectionComponent = withRouter(path)(Component)
    RouteSectionComponent.displayName = 'RouteSectionComponent'

    return RouteSectionComponent
  }, [pageRoutes])

  return <RouteSectionComponent pageRoutes={pageRoutes} {...rest} />
}
RouteSection.propTypes = {
  pageRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string
    })
  ),
  Component: PropTypes.elementType
}
export default RouteSection
