import React, { useState } from 'react'

import { block } from 'utils/classnames'

import './Tabs.sass'

export const Tabs = props => {
  const {
    defaultIndex = 0,
    children = [],
    className = '',
    type = 1
  } = props

  if (!Array.isArray(children) || !children) {
    return null
  }

  const [TabIndex, SetTabIndex] = useState(defaultIndex)

  const b = block.with('tabs-type-' + type)

  const tabsMenu = children.map(item => ({ desktopLabel: item.props.tab, mobileLabel: item.props.mobiletab }))

  return (
    <div className={`${b('')} ${className}`}>
      <ul className={b('menu')}>
        {
          tabsMenu.map((item, index) => {
            const longlabel = item.desktopLabel.length > 20

            return (
              <li
                className={b('menu-item', { active: index === TabIndex, longlabel, hasMobileLabel: !!item.mobileLabel })}
                key={index}
                onClick={() => SetTabIndex(index)}
              >
                <span className='desktop'>{item.desktopLabel}</span>
                {
                  item.mobileLabel ? (
                    <span className='mobile'>{item.mobileLabel}</span>
                  ) : null
                }
              </li>
            )
          })
        }
      </ul>
      <div className={b('content')}>
        {children[TabIndex]}
      </div>
    </div>
  )
}

export default Tabs
