import { connect } from 'react-redux'

import B2BPanel from './B2BPanel'

import {
  getMe,
  getUserCompany,
  getUserCompanyOffices,
  getAvailableCompanies,
  getFormattedCartAddress
} from 'reducers'

import {
  updateDeliveryFromAddress
} from 'actions/home/address'

import { setUserCompany } from 'actions/user'

export const ConnectedB2BPanel = connect(
  (state, props) => ({
    company: getUserCompany(state, props),
    offices: getUserCompanyOffices(state, props),
    companies: getAvailableCompanies(state, props),
    customer: getMe(state, props),
    selectedAddress: getFormattedCartAddress(state, props),
    selectCompany: setUserCompany(state, props)
  }),
  dispatch => ({
    updateDeliveryFromAddress: address => dispatch(updateDeliveryFromAddress(address, 'Votre adresse de livraison a été modifiée')),
    selectCompany: (customerId, companyId) => dispatch(setUserCompany(customerId, companyId))
  })
)(B2BPanel)

export default B2BPanel
