import { connect } from 'react-redux'
import {
  getSectionById,
  getPublicationsBySectionId,
  getNonEmptySections,
  getCurrentHash,
  getAllFilters
} from 'reducers'

import { Section } from './Section'
import SectionNavList from './SectionNavList'

function compareDates (date1, date2) {
  if (!date2) return true
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return d1.getTime() < d2.getTime()
}

export const ConnectedSection = connect(
  (state, props) => {
    const section = getSectionById(state, props)
    const publications = getPublicationsBySectionId(state, props)

    // sort publications with same idProduct by DLC / isReusable
    const publicationsMap = new Map()
    publications.forEach(elem => {
      const isReusable = elem.isReusable
      if (publicationsMap.has(elem.idProduct)) {
        // Check if publication is reusable or not : display reusable and not reusable publication
        if (isReusable) {
          // Check if endPublicationDt (DLC) is newer than endPublicationDt reusable publication saved
          if (compareDates(elem.endPublicationDt, (publicationsMap.get(elem.idProduct).reusable?.endPublicationDt || null))) {
            publicationsMap.set(elem.idProduct, { ...publicationsMap.get(elem.idProduct), reusable: elem })
          }
        } else {
          // Check if endPublicationDt (DLC) is newer than endPublicationDt noReusable publication saved
          if (compareDates(elem.endPublicationDt, (publicationsMap.get(elem.idProduct).notReusable?.endPublicationDt || null))) {
            publicationsMap.set(elem.idProduct, { ...publicationsMap.get(elem.idProduct), notReusable: elem })
          }
        }
      } else {
        let objectPub
        if (isReusable) { objectPub = { reusable: elem, notReusable: null } } else { objectPub = { reusable: null, notReusable: elem } }
        publicationsMap.set(elem.idProduct, objectPub)
      }
    })

    const publicationsArray = Array.from(publicationsMap, ([name, value]) => ({ name, value }))
    const publicationIds = publicationsArray.reduce((previous, current) => {
      if (current.value.reusable) previous.push(current.value.reusable.idProductPublication)
      if (current.value.notReusable) previous.push(current.value.notReusable.idProductPublication)
      return previous
    }, [])

    return {
      section,
      publications: publicationIds,
      shouldScrollIntoView: section && section.anchor === getCurrentHash(state, props)
    }
  }
)(Section)

export const ConnectedSectionNavList = connect(
  (state, props) => {
    const filters = getAllFilters(state, props)
    const sections = getNonEmptySections(state, props)
      .filter(section => section.type !== 'MARKETING')
    return {
      sections,
      hasFilters: Array.isArray(filters) && filters.length > 0
    }
  }
)(SectionNavList)

export default Section
