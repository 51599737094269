import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect
  , useMemo
} from 'react'

import Tabs from 'components/Common/Tabs'
import { FilterAction } from './FilterAction'
import { FilterList } from './FilterList'

import {
  addFilters,
  removeFilters,
  resetFilters
} from 'actions/home/filter'
import { getAllFilters, getAllPublications } from 'reducers'
import { filterFilters, allergenFilters } from 'config/filters'

import { block } from 'utils/classnames'
import { pushHomepageEvent } from 'utils/googletagmanager'

import './Filter.sass'

import CalorieRangeFilter from './CalorieRangeFilter'

export const Filter = props => {
  const {
    activeFilters,
    onClose,
    addFilters,
    removeFilters,
    resetFilters
  } = props

  // handle min and max to calories range from the products to redux
  const products = useSelector(getAllPublications)
  let { min, max } = products.reduce((previous, current) => {
    if (!current.nutritionalInformations.calories) return previous

    if (current.nutritionalInformations.calories > previous.max) {
      previous.max = current.nutritionalInformations.calories
    }
    if (current.nutritionalInformations.calories < previous.min) {
      previous.min = current.nutritionalInformations.calories
    }
    if (previous.min === 0) {
      previous.min = current.nutritionalInformations.calories
    }
    return previous
  }, { min: 0, max: 0 })

  max = Math.ceil(max / 5) * 5
  min = Math.floor(min / 5) * 5

  const handleClick = useCallback(
    (category, filter, isActive) => {
      if (isActive) {
        removeFilters(filter)
        return
      }
      if (category.type === 'radio') {
        removeFilters(category.filters)
      }
      addFilters(filter)
    },
    [addFilters, removeFilters]
  )

  const handleChangeCalorieRange = useCallback(
    (filter) => {
      const filters = activeFilters.filter(
        activeFilter => activeFilter.id === 'calories_range'
      )
      if (filters.length > 0) {
        removeFilters(filters)
      }
      filter && addFilters(filter)
    },
    [activeFilters, addFilters, removeFilters]
  )

  const calorieRangeFilter = useMemo(() => (
    activeFilters.find(filter => filter.id === 'calories_range')
  ), [activeFilters])

  useEffect(() => {
    pushHomepageEvent('Filters', {
      filters: activeFilters
    })
  }, [activeFilters])

  return (
    <div className={block('filter')}>
      <Tabs type={2} className={block('filter', 'tabs')}>
        <div tab='filtres'>
          <CalorieRangeFilter
            min={min}
            max={max}
            lowerValue={calorieRangeFilter?.minValue}
            upperValue={calorieRangeFilter?.maxValue}
            onChange={handleChangeCalorieRange}
          />
          {
            filterFilters.category
              .map(category => (
                <FilterList
                  key={category.label}
                  category={category}
                  title={category.label}
                  onClick={handleClick}
                  activeFilters={activeFilters}
                />
              ))
          }
        </div>
        <div tab='allergènes'>
          {
            allergenFilters.category
              .map(category => (
                <FilterList
                  key={category.label}
                  category={category}
                  onClick={handleClick}
                  activeFilters={activeFilters}
                  isAllergen
                />
              ))
          }
        </div>
      </Tabs>
      <FilterAction
        hasFilters={activeFilters.length > 0}
        handleReset={resetFilters}
        handleClose={onClose}
      />
    </div>
  )
}
Filter.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  addFilters: PropTypes.func,
  removeFilters: PropTypes.func,
  resetFilters: PropTypes.func
}

export const ConnectFilter = connect(
  (state, props) => ({
    activeFilters: getAllFilters(state)
  }),
  { addFilters, removeFilters, resetFilters }
)(Filter)

export default ConnectFilter
