import PropTypes from 'prop-types'
import React, {
  useCallback,
  useMemo,
  useState
} from 'react'

import AnimatedOverlay from 'components/Common/AnimatedOverlay'

import { block } from 'utils/classnames'

import './Offices.sass'

const b = block.with('b2b-panel-offices')

const NO_ADDRESS_LABEL = 'NO_ADDRESS'

export const Office = props => {
  const {
    office = {},
    onClick,
    disabled = false,
    expand = false
  } = props

  const handleClick = useCallback(() => {
    onClick && onClick(office)
  }, [office, onClick])

  return (
    <button
      className={
        b('item', { 'no-address': office.address === NO_ADDRESS_LABEL, expand })
      }
      onClick={handleClick}
      disabled={disabled}
    >
      {
        office.address === NO_ADDRESS_LABEL
          ? 'Autre adresse'
          : office.address
      }
    </button>
  )
}
Office.propTypes = {
  office: PropTypes.shape({
    address: PropTypes.string
  }),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  expand: PropTypes.bool
}
export const Offices = props => {
  const {
    offices = [],
    selected = null,
    onClick
  } = props

  const isCollapsed = useMemo(() => !!selected)

  const officeItems = useMemo(() => {
    if (!Array.isArray(offices) || offices.length === 0) {
      return []
    }
    return offices.sort((a, b) => (
      a.address === NO_ADDRESS_LABEL
        ? 1
        : b.address === NO_ADDRESS_LABEL
          ? -1
          : 0
    ))
  }, [offices])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const openDropdown = useCallback(() => {
    setIsDropdownOpen(true)
  }, [])
  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false)
  }, [])

  const handleClick = useCallback(
    (office) => {
      onClick && onClick(office)
      setIsDropdownOpen(false)
    },
    [onClick]
  )

  if (officeItems.length === 0) {
    return null
  }

  return (
    <div className={b()}>
      {
        selected
          ? (
            <Office
              office={selected}
              onClick={isDropdownOpen ? closeDropdown : openDropdown}
              disabled={officeItems.length === 1}
              expand
            />
          )
          : null
      }
      <AnimatedOverlay
        shouldShow={isCollapsed && isDropdownOpen}
        onClick={closeDropdown}
      />
      <ul
        className={
          b('list', {
            collapsed: isCollapsed,
            open: isCollapsed && isDropdownOpen
          })
        }
      >
        {
          officeItems.map(office => (
            <li key={office.id}>
              <Office
                office={office}
                onClick={handleClick}
                disabled={selected && office.id === selected.id}
              />
            </li>
          ))
        }
      </ul>
    </div>
  )
}
Offices.propTypes = {
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string
    })
  ),
  selected: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string
  }),
  onClick: PropTypes.func
}
export default Offices
