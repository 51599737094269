export const FILTER_PICKER_ID = 'filter'
export const ALLERGEN_PICKER_ID = 'allergen'

export const filterFilters = {
  category: [
    {
      label: 'préférences nutritionnelles',
      type: 'check',
      checkIcon: 'tick',
      filters: [
        {
          id: 'nutriscore_a',
          label: 'Nutriscore A',
          resultLabel: 'avec un Nutriscore A',
          expression: 'nutritionalInformations.nutriscore == A',
          icon: ''
        }
      ]
    },
    {
      label: 'préparation',
      type: 'radio',
      checkIcon: 'tick',
      filters: [
        {
          id: 'tag_id_not_92',
          label: 'Se mange froid',
          resultLabel: 'qui se mangent froids',
          expression: 'tags[].id != 92'
        },
        {
          id: 'tag_id_92',
          label: 'Se mange chaud',
          resultLabel: 'qui se mangent chauds',
          expression: 'tags[].id == 92'
        }
      ]
    },
    {
      label: 'préférences alimentaires',
      type: 'check',
      checkIcon: 'tick',
      filters: [
        {
          id: 'tag_id_78',
          label: '100% Végétal',
          resultLabel: '100% végétaux',
          expression: 'tags[].id == 78',
          icon: 'vegan'
        },
        {
          id: 'tag_id_63',
          label: 'Végétarien',
          resultLabel: 'végétariens',
          expression: 'tags[].id IN 63|78',
          icon: 'veggie'
        },
        {
          id: 'tag_id_57',
          label: 'Sans gluten',
          resultLabel: 'sans gluten',
          expression: 'tags[].id == 57',
          icon: 'gluten'
        },
        {
          id: 'tag_id_60',
          label: 'Sans lactose',
          resultLabel: 'sans lactose',
          expression: 'tags[].id == 60',
          icon: 'lactose'
        },
        {
          id: 'without_coriander',
          label: 'Sans coriandre',
          resultLabel: 'sans coriandre',
          expression: 'tags[].id != 146',
          icon: 'coriander'
        },
        {
          id: 'tag_id_not_81',
          label: 'Sans porc',
          resultLabel: 'sans porc',
          expression: 'tags[].id != 81',
          icon: 'pork'
        },
        {
          id: 'tag_id_not_84',
          label: 'Sans alcool',
          resultLabel: 'sans alcool',
          expression: 'tags[].id != 84',
          icon: 'alcohol'
        }
      ]
    }
  ]
}

export const allergenFilters = {
  category: [
    {
      label: 'allergènes',
      type: 'check',
      checkIcon: 'banned',
      filters: [
        {
          id: 'idProductAllergen_950',
          label: 'Arachide',
          resultLabel: 'sans arachides',
          expression: 'allergens[].id != 950'
        },
        {
          id: 'idProductAllergen_906',
          label: 'Céleri',
          resultLabel: 'sans céleri',
          expression: 'allergens[].id != 906'
        },
        {
          id: 'idProductAllergen_943',
          label: 'Crustacés',
          resultLabel: 'sans crustacés',
          expression: 'allergens[].id != 943'
        },
        {
          id: 'idProductAllergen_946',
          label: 'Fruit à coque',
          resultLabel: 'sans fruits à coque',
          expression: 'allergens[].id != 946'
        },
        {
          id: 'idProductAllergen_891',
          label: 'Gluten',
          resultLabel: 'sans gluten',
          expression: 'allergens[].id != 891'
        },
        {
          id: 'idProductAllergen_993',
          label: 'Lactose',
          resultLabel: 'sans lactose',
          expression: 'allergens[].id != 993'
        },
        {
          id: 'idProductAllergen_981',
          label: 'Lupin',
          resultLabel: 'sans lupin',
          expression: 'allergens[].id != 981'
        },
        {
          id: 'idProductAllergen_992',
          label: 'Mollusque',
          resultLabel: 'sans mollusques',
          expression: 'allergens[].id != 992'
        },
        {
          id: 'idProductAllergen_927',
          label: 'Moutarde',
          resultLabel: 'sans moutarde',
          expression: 'allergens[].id != 927'
        },
        {
          id: 'idProductAllergen_903',
          label: 'Œuf',
          resultLabel: 'sans œufs',
          expression: 'allergens[].id != 903'
        },
        {
          id: 'idProductAllergen_958',
          label: 'Poisson',
          resultLabel: 'sans poissons',
          expression: 'allergens[].id != 958'
        },
        {
          id: 'idProductAllergen_897',
          label: 'Sésame',
          resultLabel: 'sans sésame',
          expression: 'allergens[].id != 897'
        },
        {
          id: 'idProductAllergen_954',
          label: 'Soja',
          resultLabel: 'sans soja',
          expression: 'allergens[].id != 954'
        },
        {
          id: 'idProductAllergen_933',
          label: 'Sulfites',
          resultLabel: 'sans sulfites',
          expression: 'allergens[].id != 933'
        }
      ]
    }
  ]
}

export const FILTERS = {
  publications: {
    [FILTER_PICKER_ID]: filterFilters,
    [ALLERGEN_PICKER_ID]: allergenFilters
  }
}
