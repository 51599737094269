import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './SectionHead.sass'

const b = block.with('section-head')

export const SectionHead = props => {
  const {
    title,
    subtitle = null,
    icon = null,
    type
  } = props

  if (type === 'MARKETING') {
    return null
  }

  return (
    <header title={title} className={b('', { type: type.toLowerCase() })}>
      {
        icon
          ? (
            <div
              className={b('icon')}
              style={{ backgroundImage: `url(${icon})` }}
            />
          )
          : null
      }
      <div className={b('heading')}>
        <h3 className={b('title')}>
          {title}
        </h3>
        {
          subtitle
            ? (
              <h4 className={b('subtitle')}>
                <span>{subtitle}</span>
              </h4>
            )
            : null
        }
      </div>
    </header>
  )
}
SectionHead.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  anchor: PropTypes.string,
  type: PropTypes.string
}
export default SectionHead
