import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { isEqual } from 'lodash'

import { ConnectedSection as Section } from './Section'
import { ConnectFilterInfo as FilterInfo } from 'components/Filter/FilterInfo'
import { ConnectedEmptyProductPage as EmptyProductPage } from './EmptyProductPage'

import { getCartDelivery, getIsPageLoading, getNonEmptySections, getShiftById } from 'reducers'

import { getProductPublications } from 'actions/home/publications'

import { block } from 'utils/classnames'

import './ProductPage.sass'

const b = block.with('product-page')

const ProductPage = () => {
  const dispatch = useDispatch()
  const sections = useSelector(getNonEmptySections)
  const { idShift, idArea } = useSelector(getCartDelivery, isEqual) || {}
  const selectedShift = useSelector(state => getShiftById(state, { shiftId: idShift }), isEqual)
  const isPageLoading = useSelector(getIsPageLoading)

  useEffect(() => {
    if (!isPageLoading && selectedShift) {
      dispatch(getProductPublications(selectedShift))
    }
  }, [idShift, idArea])

  return (
    <div className={b()}>
      <FilterInfo />
      <ul className={b('publication-list')}>
        {
          Array.isArray(sections) && sections.length > 0
            ? sections.map(section => (
              <Section
                key={section.id}
                sectionId={section.id}
              />
            ))
            : <EmptyProductPage />
        }
      </ul>
    </div>
  )
}
export default ProductPage
