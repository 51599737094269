import { useSelector } from 'react-redux'
import React from 'react'

import { getLayoutHeaders } from 'reducers'

import { Banner } from './Banner'

export const ConnectedBanner = props => {
  const config = useSelector(getLayoutHeaders)
  return (
    <Banner
      {...props}
      headers={config}
    />
  )
}

export default Banner
