import React from 'react'
import { Range } from 'rc-slider'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'

import { block } from 'utils/classnames'
import { BREAKPOINT_MOBILE } from 'utils/device'

import 'rc-slider/assets/index.css'
import './CalorieRangeFilter.sass'

const b = block.with('calorie-range-filter')

const INTERVAL = 300
const STEP = 5

const getHomePageLabel = (min, max, lowerValue, upperValue) => {
  if (lowerValue <= min && upperValue >= max) {
    return 'aucun filtre'
  }
  if (lowerValue <= min) {
    return `de moins de ${upperValue} calories`
  }
  if (upperValue < max) {
    return `entre ${lowerValue} et ${upperValue} calories`
  }
  return `de plus de ${lowerValue} calories`
}

const getFilterPanelLabel = (min, max, lowerValue, upperValue) => {
  if (lowerValue <= min && upperValue >= max) {
    return 'aucun filtre'
  }
  if (lowerValue <= min) {
    return `moins de ${upperValue} kcal`
  }
  if (upperValue < max) {
    return `entre ${lowerValue} et ${upperValue} kcal`
  }
  return `plus de ${lowerValue} kcal`
}

export const CalorieRangeFilter = ({ min, max, lowerValue, upperValue, onChange }) => {
  const handleOnChange = values => {
    const lowerRequestedValue = values[0]
    const upperRequestedValue = values[1]
    if (upperRequestedValue === max && min === lowerRequestedValue) {
      onChange && onChange(null)
      return
    }
    if (upperRequestedValue - lowerRequestedValue < INTERVAL) {
      if (upperValue - lowerValue > INTERVAL) {
        const newUppperValue = upperRequestedValue === upperValue ? upperRequestedValue : lowerRequestedValue + INTERVAL
        const newLowerValue = lowerRequestedValue === lowerValue ? lowerRequestedValue : upperRequestedValue - INTERVAL
        const label = getHomePageLabel(min, max, newLowerValue, newUppperValue)
        onChange && onChange({
          id: 'calories_range',
          label: label,
          resultLabel: label,
          expression: `nutritionalInformations.calories >< ${newLowerValue}|${newUppperValue}`,
          icon: '',
          minValue: newLowerValue,
          maxValue: newUppperValue
        })
      }
      return
    }
    const label = getHomePageLabel(min, max, lowerRequestedValue, upperRequestedValue)
    onChange && onChange({
      id: 'calories_range',
      label: label,
      resultLabel: label,
      expression: `nutritionalInformations.calories >< ${lowerRequestedValue}|${upperRequestedValue}`,
      icon: '',
      minValue: lowerRequestedValue,
      maxValue: upperRequestedValue
    })
  }

  const filterPanelLabel = getFilterPanelLabel(min, max, lowerValue || min, upperValue || max)

  const isMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINT_MOBILE - 1}px)` })
  const limitsStyle = {
    borderColor: 'black',
    boxShadow: '0 0 0 0px black',
    width: '8px',
    height: isMobile ? '16px' : '8px',
    borderRadius: isMobile ? '100px' : '50%',
    backgroundColor: 'black',
    cursor: 'pointer'
  }
  const trackStyle = {
    borderRadius: 70,
    backgroundColor: 'black',
    height: 2,
    transform: isMobile ? 'translateY(2px)' : 'translateY(-2px)'
  }
  const railStyle = {
    borderRadius: 70,
    backgroundColor: '#D8D8D8',
    height: 2,
    transform: isMobile ? 'translateY(2px)' : 'translateY(-2px)'
  }

  return (
    <section
      className={b()}
    >
      <div className={b('head')}>
        <h4>
          Calories
        </h4>
        <p>
          {filterPanelLabel}
        </p>
      </div>
      <Range
        className={b('range')}
        trackStyle={[trackStyle, trackStyle]}
        handleStyle={[limitsStyle, limitsStyle]}
        railStyle={railStyle}
        value={[lowerValue || min, upperValue || max]}
        min={min}
        step={STEP}
        max={max}
        allowCross={false}
        onChange={handleOnChange}
      />
    </section>
  )
}
CalorieRangeFilter.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  lowerValue: PropTypes.number,
  upperValue: PropTypes.number,
  onChange: PropTypes.func
}
export default CalorieRangeFilter
