import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useMemo
} from 'react'

import { ProductSheet } from './ProductSheet'

import { block } from 'utils/classnames'
import { pushProductPageEvent, pushHomepageEvent } from 'utils/googletagmanager'

import './ProductPanel.sass'

const b = block.with('product-panel')

export const ProductPanel = props => {
  const {
    productId,
    product = {},
    publication,
    basketProduct,
    hasError = false,
    saleIsBlocked = false,
    updateCartProducts,
    getProductDetails,
    onClose,
    isDemo = false,
    isMobile = false
  } = props

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    getProductDetails && getProductDetails(productId)
  }, [])

  useEffect(() => {
    hasError && onClose && onClose()
  }, [hasError, onClose])

  useEffect(() => {
    product && pushHomepageEvent('OpenProductPage', {
      productId,
      publicationId: publication && publication.idProductPublication,
      productName: product.title
    })
  }, [productId, product, publication])

  const quantity = useMemo(() => basketProduct?.quantity || 0, [basketProduct])
  const isLast = useMemo(() => basketProduct?.isLast || false, [basketProduct])

  const handleSetQuantity = useCallback((publicationId, newQuantity) => {
    if (newQuantity > quantity) {
      pushProductPageEvent('AddToCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    if (newQuantity < quantity) {
      pushProductPageEvent('RemoveFromCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    updateCartProducts && updateCartProducts(publicationId, newQuantity)
  }, [product, quantity])

  if (!product) {
    return null
  }

  return (
    <div className={b()}>
      <button
        type='button'
        aria-label='Fermer la fiche produit'
        className={b('close')}
        onClick={onClose}
        disabled={!onClose}
      >
        <svg width='30' height='30' viewBox='0 0 30 30' fill='white' stroke='black' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.5' y='0.5' width='29' height='29' rx='14.5' fill='inherit' stroke='inherit' />
          <path fillRule='evenodd' clipRule='evenodd' d='M9.28754 9.28619C9.53418 9.03955 9.93408 9.03955 10.1807 9.28619L20.7153 19.8208C20.9619 20.0674 20.9619 20.4673 20.7153 20.714C20.4686 20.9606 20.0688 20.9606 19.8221 20.714L9.28754 10.1794C9.04089 9.93273 9.04089 9.53284 9.28754 9.28619Z' fill='black' />
          <path fillRule='evenodd' clipRule='evenodd' d='M20.6148 9.18737C20.8657 9.42967 20.8727 9.82951 20.6304 10.0804L10.2812 20.7972C10.0389 21.0482 9.63911 21.0551 9.3882 20.8128C9.13728 20.5705 9.1303 20.1707 9.37261 19.9198L19.7217 9.20295C19.964 8.95204 20.3639 8.94506 20.6148 9.18737Z' fill='black' />
        </svg>
      </button>
      <ProductSheet
        isMobile={isMobile}
        product={product}
        publication={publication && publication}
        publicationId={publication && publication.idProductPublication}
        timeframe={publication && publication.timeFrame}
        price={publication && publication.price}
        originalPrice={publication && publication.originalPrice}
        menuItems={publication && publication.menuItems}
        quantity={quantity}
        isLast={isLast}
        saleIsBlocked={saleIsBlocked}
        updateCartProducts={handleSetQuantity}
        isDemo={isDemo}
      />
    </div>
  )
}
ProductPanel.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  product: PropTypes.object,
  publication: PropTypes.object,
  basketProduct: PropTypes.object,
  saleIsBlocked: PropTypes.bool,
  updateCartProducts: PropTypes.func,
  getProductDetails: PropTypes.func,
  isDemo: PropTypes.bool
}
export default ProductPanel
