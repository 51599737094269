import React from 'react'

import { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP_XL } from 'utils/device'

export const ImageResponsive = props => {
  const {
    img,
    imgRetina,
    mobile,
    mobileRetina,
    tablet,
    tabletRetina,
    desktop,
    desktopRetina,
    alt
  } = props

  if (!img && !mobile) {
    return null
  }

  return (
    <picture>
      {
        desktop
          ? (
            <source
              media={`(min-width: ${BREAKPOINT_DESKTOP_XL}px)`}
              srcSet={`${desktop}, ${desktopRetina ? desktopRetina + ' 2x' : null}`}
            />
          ) : null
      }
      {
        tablet
          ? (
            <source
              media={`(min-width: ${BREAKPOINT_TABLET}px)`}
              srcSet={`${tablet}, ${tabletRetina ? tabletRetina + ' 2x' : null}`}
            />
          ) : null
      }
      <img
        alt={alt}
        srcSet={`${img || mobile}, ${imgRetina || mobileRetina ? imgRetina || mobileRetina + ' 2x' : null}`}
      />
    </picture>
  )
}

export default ImageResponsive
