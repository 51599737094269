import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import React from 'react'

import RouteButton from 'components/Router/RouteButton'

import { block } from 'utils/classnames'

import './SectionNav.sass'

const b = block.with('section-nav')

export const SectionNav = props => {
  const {
    sections,
    hasFilters = false,
    className = ''
  } = props

  const anchors = React.useMemo(
    () => (
      sections.map(section => 'section_' + section.anchor)
    ),
    [sections]
  )

  return (
    <nav className={`${b()} ${className}`.trim()}>
      <Scrollspy
        items={anchors}
        offset={-100}
        className={b('sections')}
        currentClassName={b('item', { active: true })}
      >
        {
          Array.isArray(sections)
            ? sections.map(section => (
              <li
                key={section.id}
                className={b('item')}
              >
                <a href={`#${section.anchor}`}>
                  {section.alias || section.title}
                </a>
              </li>
            ))
            : null
        }
      </Scrollspy>
      <div className={b('filter-trigger', { active: hasFilters })}>
        <RouteButton routeId='filter'>
          Filtres
        </RouteButton>
      </div>
    </nav>
  )
}
SectionNav.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      anchor: PropTypes.string,
      alias: PropTypes.string,
      title: PropTypes.string
    })
  ),
  hasFilters: PropTypes.bool,
  className: PropTypes.string
}

export default SectionNav
