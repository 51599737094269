import React, { useState, useCallback } from 'react'

import { block } from 'utils/classnames'

import { useTransition, animated } from 'react-spring'

import './Carousel.sass'

export const Carousel = props => {
  const {
    children,
    hasControl = true,
    hasPagination = true
  } = props

  if (!children && !Array.isArray(children)) {
    return null
  }

  const [currentFrame, setCurrentFrame] = useState(0)

  const goToIndex = useCallback((currentFrame) => {
    setCurrentFrame(state => currentFrame)
  }, [])
  const goToNext = useCallback(() => goToIndex((currentFrame + 1) % children.length, [currentFrame]))
  const goToPrev = useCallback(() => goToIndex(currentFrame === 0 ? children.length - 1 : currentFrame - 1, [currentFrame]))

  const Frames = React.useMemo(
    () => React.Children.map(children, e => e).map(child =>
      ({ style }) => (
        <animated.div style={{ ...style }}>
          {child}
        </animated.div>
      )
    ),
    // eslint-disable-next-line
    []
  )

  const transitions = useTransition(currentFrame, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      <div className={block('slider')}>
        {
          transitions.map(({ item, props, key }) => {
            const Frame = Frames[item]
            return <Frame key={key} style={props} />
          })
        }
        {
          hasControl && children.length > 1 ? (
            <>
              <button className={block('slider', 'btn-prev')} onClick={goToPrev} />
              <button className={block('slider', 'btn-next')} onClick={goToNext} />
            </>
          ) : null
        }
        {
          hasPagination && children.length > 1 ? (
            <ul className={block('slider', 'pagination')}>
              {
                children.map((item, index) => {
                  const active = currentFrame === index

                  return (
                    <li onClick={() => goToIndex(index)} className={block('slider', 'pagination-page', { active })} key={index} />
                  )
                })
              }
            </ul>
          ) : null
        }
      </div>
    </>
  )
}

export default Carousel
