import { useSelector } from 'react-redux'

import React from 'react'

import { getDevice, getMe, isDemoModeEnabled } from 'reducers'

import Header, {
  ConnectedSmallHeader as SmallHeader
} from 'components/layout/Header'
import { ConnectedBanner as Banner } from 'components/layout/Header/Banner'
import BasketPanel from 'components/Basket'
import OrderSummaryPanel from 'components/Order/OrderSummaryPanel'
import { ConnectedCartButton as CartButton } from 'components/Cart/CartButton'
import { ConnectedCartMobileButton as CartMobileButton } from 'components/Cart/CartMobileButton'
import { ConnectedInactivityWatcher } from 'components/Cart/InactivityWatcher'
import DeliveryBar from 'components/DeliveryBar'
import FilterPanel from 'components/Filter/Filter'
import Footer from 'components/layout/Footer'
import { ConnectedGlobalErrorMessage as GlobalErrorMessage } from 'components/Common/GlobalErrorMessage'
import ProductPage from 'components/product/ProductPage'
import { ConnectedProductPanel as ProductPanel } from 'components/product/ProductPanel'
import RouteButton from 'components/Router/RouteButton'
import RouteModal from 'components/Router/RouteModal'
import RoutePanel from 'components/Router/RoutePanel'
import RouteSection from 'components/Router/RouteSection'
import { RouterProvider } from 'hooks/router'
import {
  ConnectedSectionNavList as SectionNavList
} from 'components/product/Section'
import { ConnectedSnackbar as Snackbar } from 'components/Common/Snackbar'
import { Sticky } from 'components/Common/Sticky'
import UserForm from 'components/user/UserForm'
import { ConnectedSlideshow as ProductSlideshow } from 'components/product/Slideshow'
import { ConnectedRouteDeliveryTrackingButton as DeliveryTrackingButton } from 'components/user/DeliveryTrackingButton'
import { ConnectedEvent as Event } from 'components/Event'
import { ConnectedB2BPanel as B2BPanel } from 'components/user/B2BPanel'
import { ConnectedWelcomeModal as WelcomeModal } from 'components/user/WelcomeModal'
import Router from 'next/router'

import { getRouteById, getPathById } from 'config/router'

import * as device from 'utils/device'

import './Home.sass'
import { ConnectedPopinErrorModal } from 'components/Basket/BasketPayment/PopinErrorModal'
import UnavailableShiftTimeSlotErrorModal from 'components/Basket/BasketPayment/UnavailableShiftTimeSlotErrorModal'

const pageRoutes = [
  {
    id: 'home',
    path: '/',
    title: 'FoodChéri'
  }
]
export const Home = (props) => {
  const { isDesktop } = useSelector(getDevice)
  const isDemo = useSelector(isDemoModeEnabled)
  const user = useSelector(getMe)
  const isConnected = !!user.firstName

  const pathName = window.location.pathname
  if (!isConnected && pathName.includes('/suivi')) {
    Router.push('/connexion')
  }

  return (
    <RouterProvider>
      <div>
        <Event />

        <Header isHomepage isDesktop={isDesktop} />

        <SmallHeader
          CartButton={(
            <RouteButton
              route={getRouteById('cart')}
              params={{ step: '' }}
              Button={CartButton}
            />
          )}
        />
        <Sticky className='delivery-bar-container'>
          <DeliveryBar />
          {isDemo
            ? null
            : (
              <RouteButton
                route={getRouteById('cart')}
                params={{ step: '' }}
                Button={CartButton}
              />
            )}
        </Sticky>

        <Banner />

        <Sticky className='section-nav-container'>
          <SectionNavList />
          <div className='cart-button-container'>
            {isDemo
              ? null
              : (
                <RouteButton
                  route={getRouteById('cart')}
                  params={{ step: '' }}
                  Button={CartButton}
                />
              )}
          </div>
        </Sticky>

        <RouteSection
          pageRoutes={pageRoutes}
          Component={ProductPage}
        />

        <Footer withNewsletterForm />
        <ProductSlideshow />
        <DeliveryTrackingButton
          route={getRouteById('tracking')}
        />

        {
          device.default.isMobile()
            ? (
              <RouteButton
                route={getRouteById('cart')}
                params={{ step: '' }}
                Button={CartMobileButton}
              />
            )
            : null
        }

        <RoutePanel
          path={getPathById('cart')}
          params={{ step: '' }}
          Component={BasketPanel}
        />

        <RoutePanel
          path={getPathById('filter')}
          Component={FilterPanel}
          panelClassName='filter-panel'
        />

        <RoutePanel
          path={getPathById('product')}
          Component={ProductPanel}
          position={isDesktop ? 'left' : 'right'}
          isFullsSreenOnMobile
        />

        <RouteModal
          path={getPathById('auth')}
          Component={UserForm}
          isFullsSreenOnMobile
        />

        <RoutePanel
          path={getPathById('tracking')}
          Component={isConnected ? OrderSummaryPanel : null}
          params={{ step: '' }}
        />

        <RoutePanel
          path={getPathById('b2b-panel')}
          Component={B2BPanel}
          canClose={false}
        />

        <Snackbar />
        <GlobalErrorMessage />
        <ConnectedInactivityWatcher />
        <ConnectedPopinErrorModal />
        <UnavailableShiftTimeSlotErrorModal />
        <WelcomeModal />
      </div>
    </RouterProvider>
  )
}

export default Home
