import { connect } from 'react-redux'

import {
  getPublicationById,
  getCartProduct,
  getSaleIsBlocked,
  isDemoModeEnabled
} from 'reducers'

import {
  updateCartProducts
} from 'actions/home/cart'

import ProductCard from './ProductCard'

const ConnectedProductCard = connect(
  (state, props) => ({
    product: getPublicationById(state, props),
    basketProduct: getCartProduct(state, props),
    saleIsBlocked: getSaleIsBlocked(state, props),
    isDemo: isDemoModeEnabled(state, props)
  }),
  (dispatch) => ({
    onChange: (publicationId, quantity) => dispatch(updateCartProducts(publicationId, quantity))
  })
)(ProductCard)

export default ConnectedProductCard
