import PropTypes from 'prop-types'
import React, { useCallback, useRef, useEffect, useState } from 'react'

import { SectionHead } from './SectionHead'
import Catalog from 'components/product/Catalog'

import { block } from 'utils/classnames'

import './Section.sass'

const b = block.with('publication-section')

export const Section = props => {
  const {
    section,
    publications = [],
    className = '',
    shouldScrollIntoView = false
  } = props

  const [hideLastAdCard, setHideLastAdCard] = useState(false)

  /**
   * In order to avoid some unwanted spacing between sections, as well as for aesthetics reasons,
   * we want to hide the last adCard of a section if is going to be the only card on it's row.
   */
  const shouldHideLastAdCard = () => {
    const cardAmount = publications.length + section.adCards?.length
    const width = window.innerWidth

    // we determinate the number of columns from the window's width
    let columns = 0
    if (width < 767) {
      columns = 2
    } else if (width >= 767 && width <= 992) {
      columns = 3
    } else if (width > 992) {
      columns = 4
    }

    // using a modulo, we find the number of cards on the last row
    if (cardAmount % columns === 1) {
      return true
    } else {
      return false
    }
  }

  const handleResize = () => {
    setHideLastAdCard(shouldHideLastAdCard)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const anchorElement = useRef(null)
  const handleLoad = useCallback(
    () => {
      shouldScrollIntoView &&
        anchorElement.current &&
        anchorElement.current.scrollIntoView()
    },
    []
  )

  const wrapperClass = `${b('', { [section.anchor]: true })} ${className}`.trim()

  return (
    <section onLoad={handleLoad} id={'section_' + section.anchor} className={wrapperClass}>
      <a ref={anchorElement} id={section.anchor} aria-label={section.title} />
      <div className='grid-container container catalog'>
        <SectionHead {...section} />
        <div className='grid-container'>
          <div className='grid-row'>
            {
              Array.isArray(publications)
                ? (
                  <Catalog
                    publications={publications}
                    adCards={section.adCards}
                    hideLastAdCard={hideLastAdCard}
                  />
                )
                : null
            }
          </div>
        </div>
      </div>
    </section>
  )
}
Section.propTypes = {
  section: PropTypes.shape({
    anchor: PropTypes.string,
    adCards: PropTypes.array
  }).isRequired,
  publications: PropTypes.array,
  shouldScrollIntoView: PropTypes.bool,
  className: PropTypes.string
}
export default Section
