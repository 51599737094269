import PropTypes from 'prop-types'
import React from 'react'

import RouteButton from 'components/Router/RouteButton'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'

import './Footer.sass'

const b = block.with('b2b-panel-footer')

export const Footer = props => {
  let {
    referrerAmount = 0,
    referredAmount = 0,
    onClose
  } = props

  referrerAmount = parseFloat(referrerAmount)
  referrerAmount = isNaN(referrerAmount) ? 0 : referrerAmount

  if (!referrerAmount) {
    return null
  }

  referredAmount = parseFloat(referredAmount)
  referredAmount = isNaN(referredAmount) ? 0 : referredAmount

  return (
    <footer className={b()}>
      <RouteButton
        buttonProps={{
          className: b('button', { referral: true })
        }}
        routeId='referral'
      >
        Parrainez vos collègues et partagez {
          eur(referrerAmount + referredAmount)
        }
      </RouteButton>
      <button
        className={b('button', { close: true })}
        onClick={onClose}
      >
        Voir la carte
      </button>
    </footer>
  )
}
Footer.propTypes = {
  referrerAmount: PropTypes.number,
  referredAmount: PropTypes.number,
  onClose: PropTypes.func
}
export default Footer
