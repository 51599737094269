import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { PREORDER_LIMIT_IN_DAYS } from 'config/delivery'

import { block } from 'utils/classnames'

import {
  getAvailableDays,
  getSelectedDay,
  hasAppliedFilters
} from 'reducers'
import { updateDeliveryFromDay } from 'actions/home/delivery'

import './EmptyProductPage.sass'

const b = block.with('empty-product-page')

export const isPreorder = day => {
  if (!day) {
    return false
  }

  if (!moment(day).isValid()) {
    return false
  }

  day = moment.utc(day).startOf('day')
  const today = moment.utc().startOf('day')

  if (today.isAfter(day)) {
    return false
  }

  let days = 0
  let diff = day.diff(today, 'days')

  while (diff > 0) {
    today.add(1, 'day')
    diff = day.diff(today, 'days')
    if (today.isoWeekday() <= 5) days += 1
  }

  return days > PREORDER_LIMIT_IN_DAYS
}

export const EmptyProductPage = props => {
  const {
    deliveryDate,
    firstAvailableDate,
    updateDeliveryFromDay
  } = props

  const handleClick = useCallback(() => {
    if (updateDeliveryFromDay && firstAvailableDate) {
      updateDeliveryFromDay(firstAvailableDate)
    }
  }, [updateDeliveryFromDay, firstAvailableDate])

  const isTooSoon = useMemo(() => (
    isPreorder(deliveryDate)
  ), [deliveryDate])

  return (
    <div className={b('', { 'too-soon': isTooSoon })}>
      {
        isTooSoon
          ? (
            <>
              <h4>
                Mais où est passé le reste des produits ?
              </h4>
              <p>
                L’ensemble de notre carte est disponible jusqu’à {PREORDER_LIMIT_IN_DAYS} jours à l’avance.
                <br />
                Au delà, seule une partie des produits est disponible à la précommande.
              </p>
              <button
                disabled={!updateDeliveryFromDay || !firstAvailableDate} onClick={handleClick}
              >
                Revenir à la carte d'aujourd'hui
              </button>
            </>
          )
          : (
            <h3>
              Oups ! Aucun produit ne correspond à vos critères ;)
            </h3>
          )
      }
    </div>
  )
}
EmptyProductPage.propTypes = {
  deliveryDate: PropTypes.string,
  firstAvailableDate: PropTypes.string,
  updateDeliveryFromDay: PropTypes.func
}
export const ConnectedEmptyProductPage = connect(
  (state, props) => {
    const availableDays = getAvailableDays(state, props)
    return {
      deliveryDate: hasAppliedFilters(state, props) ? null : getSelectedDay(state, props),
      firstAvailableDate: availableDays && availableDays[0]
    }
  },
  { updateDeliveryFromDay }
)(EmptyProductPage)

export default EmptyProductPage
